import { memo } from 'react';

import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { AdfoxContextData, AdfoxContextProvider, PParams } from '../../context/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'offersSerp',
  name__DEPRECATED: 'Listing_parallax',
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '500px' };
const P_PARAMS: PParams = { p1: 'bvkoa', p2: 'y' };

type ParallaxBannerProps = {
  position: number;
};

export const ParallaxBanner = memo<ParallaxBannerProps>(function ParallaxBanner(props) {
  const { position } = props;

  return (
    <AdfoxContextProvider pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE}>
      <AdfoxBannerContainer position={position} />
    </AdfoxContextProvider>
  );
});
