import { IStyleConfig, mergeStyles } from '@cian/utils/lib/shared/style';

import * as React from 'react';

import { IPhone, IWorkTimeInfo } from 'shared/offer';

import { getFormattedPhoneNumber } from '../../../../utils/format';

import { WarningMessage } from './WarningMessage';
import { WorkTimeInfo } from './WorkTimeInfo';
import { SimplePhoneButton } from './simple_button';

const style = require('./index.css');

const EMPTY_PHONES = [{ countryCode: null, number: null }];

export interface IPhoneProps {
  phones: IPhone[];
  workTimeInfo: IWorkTimeInfo | null;
  isSimplified?: boolean;
  showWarningMessage: boolean;

  onPhoneOpened?(): void;
}

enum EPhoneState {
  BUTTON,
  TEXT,
}

interface IPhoneState {
  type: EPhoneState;
  text: string;
  textStyle: IStyleConfig;
  phoneOpened: boolean;
}

export class Phone extends React.Component<IPhoneProps, IPhoneState> {
  public static defaultProps = {
    showWarningMessage: false,
  };

  private phoneNumber: string;
  private hoverText = 'Показать телефон';

  public constructor(props: IPhoneProps) {
    super(props);
    this.phoneNumber = getFormattedPhoneNumber(props.phones[0]);
    this.state = {
      text: this.hideLastDigits(this.phoneNumber),
      textStyle: style['phone'],
      type: EPhoneState.BUTTON,
      phoneOpened: false,
    };
  }

  public render() {
    let viewer: React.ReactNode;
    const { workTimeInfo, isSimplified, showWarningMessage } = this.props;

    if (this.state.type === EPhoneState.BUTTON) {
      viewer = <PhoneText isSimplified={!!isSimplified} text={this.state.text} textStyle={this.state.textStyle} />;
    }

    const phones = this.state.phoneOpened ? this.props.phones : EMPTY_PHONES;

    const workTime = <WorkTimeInfo workTimeInfo={workTimeInfo} />;
    const warningMessage = showWarningMessage ? <WarningMessage /> : null;

    return (
      <SimplePhoneButton
        isFullWidth={true}
        phones={phones}
        text={viewer}
        warningMessage={warningMessage}
        workTime={workTime}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        onPhoneOpened={this.handleOpen}
      />
    );
  }

  private onMouseOver = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hoverText,
        textStyle: style['hoverText'],
      });
    }
  };

  private onMouseOut = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hideLastDigits(this.phoneNumber),
        textStyle: style['phone'],
      });
    }
  };

  private hideLastDigits = (phoneNumber: string) => {
    return phoneNumber.slice(0, -2).concat('…');
  };

  private handleOpen = async () => {
    this.setState({
      text: this.phoneNumber,
      textStyle: style['phone'],
      type: EPhoneState.TEXT,
      phoneOpened: true,
    });
    // else невозможно покрыть
    /* istanbul ignore else */
    if (this.props.onPhoneOpened) {
      this.props.onPhoneOpened();
    }
  };
}

export interface IPhoneTextProps {
  text: string;
  textStyle: IStyleConfig;
  isSimplified: boolean;
}

export function PhoneText({ text, textStyle, isSimplified }: IPhoneTextProps) {
  return <span {...mergeStyles([textStyle, isSimplified && style['simplified-phone']])}>{text}</span>;
}
