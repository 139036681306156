/* eslint-disable max-lines */
import { TGeoValue } from '../../../common/json_query';

import { AgentModerationInfoSchema } from './AgentModerationInfoSchema';
import { IModerationInfoSchema } from './ModerationInfoSchema';
import { IBargainTerms } from './bargainTerms';
import { IBookingData } from './booking';
import { IFactoid } from './factoids';
import { IIdenticalOffers } from './identicalOffers';
import { IOfferComparisonStatus } from './offerComparison';
import { IPhotoFeatureIcons } from './photoFeatureIcons';
import { IPhotoLabel } from './photoLabels';
import { IProfitbaseBooking } from './profitbaseBooking';

export type TAccessType = 'free' | 'passSystem';
export type TBuilding =
  | 'Бизнес-центр'
  | 'Торгово-развлекательный центр'
  | 'Старый фонд'
  | 'Офисное здание'
  | 'Бизнес-парк'
  | 'Офисно-производственный комплекс'
  | 'Офисно-складской комплекс'
  | 'Складской комплекс'
  | 'Производственно-складской комплекс'
  | 'Другое'
  | 'Логистический центр'
  | 'Склад'
  | 'Административное здание'
  | 'Индустриальный парк'
  | 'Логистический комплекс'
  | 'Торгово-деловой комплекс'
  | 'Многофункциональный комплекс'
  | 'Офисно-жилой комплекс'
  | 'Торгово-офисный комплекс'
  | 'Офисно-гостиничный комплекс'
  | 'Деловой центр'
  | 'Особняк'
  | 'Технопарк'
  | 'Бизнес-квартал'
  | 'Отдельно стоящее здание'
  | 'Жилой дом'
  | 'Жилой комплекс'
  | 'Торговый центр'
  | 'Специализированный торговый центр'
  | 'Торгово-общественный центр'
  | 'Аутлет'
  | 'Производственный комплекс'
  | 'Промплощадка'
  | 'Производственный цех'
  | 'Объект свободного назначения'
  | 'Имущественный комплекс'
  | 'Производственное здание'
  | 'Модульное здание'
  | 'Свободное'
  | 'Офисно-складское'
  | 'Нежилой фонд'
  | 'Жилой фонд';
export type TBuildingMaterial =
  | 'monolith'
  | 'monolithBrick'
  | 'brick'
  | 'panel'
  | 'block'
  | 'wood'
  | 'stalin'
  | 'boards'
  | 'old';
export type TCommercialClass = 'aPlus' | 'a' | 'bPlus' | 'b' | 'bMinus' | 'cPlus' | 'c' | 'd';
export type TOfferBSCenterType = 'businessCenter' | 'shoppingCenter';
export type TOfferCategory = `${
  | 'commercialLand'
  | 'freeAppointmentObject'
  | 'garage'
  | 'industry'
  | 'land'
  | 'office'
  | 'shoppingArea'
  | 'townhouse'
  | 'warehouse'
  | 'business'
  | 'manufacture'}${'Sale' | 'Rent'}`;

export type TDealType = 'rent' | 'sale' | 'daily';
export type TGarageGarageType =
  // Встроенный
  | 'builtIn'
  // Капитальный
  | 'capital'
  // Самострой
  | 'samostroy'
  // Ракушка
  | 'shell';
export type TGarageMaterial =
  // Кирпичный
  | 'brick'
  // Металлический
  | 'metal';
export type TGarageStatus =
  // По доверенности
  | 'byProxy'
  // Кооператив
  | 'cooperative'
  // Собственность
  | 'ownership';
export type TGarageType =
  // Бокс
  | 'box'
  // Гараж
  | 'garage'
  // Машиноместо
  | 'parkingPlace';
export type TLandAreaUnitType = 'hectare' | 'sotka';
export type TLandStatus =
  // Фермерское хозяйство
  | 'farm'
  // Участок сельскохозяйственного назначения
  | 'forAgriculturalPurposes'
  // Участок лесного фонда
  | 'forestArea'
  // Садоводство
  | 'gardening'
  // Садовое некоммерческое товарищество
  | 'gardeningNonProfitPartnership'
  // Индивидуальное жилищное строительство
  | 'individualHousingConstruction'
  // Земля промышленного назначения
  | 'industrialLand'
  // Участок промышленности, транспорта, связи и иного не сельхоз. назначения
  | 'industryTransportCommunications'
  // Инвестпроект
  | 'investmentProject'
  // Особо охраняемых категорий
  | 'ofProtectedCategories'
  // Участок запаса
  | 'reserve'
  // Участок поселений
  | 'settlements'
  // Дачное некоммерческое партнерство
  | 'suburbanNonProfitPartnership'
  // Дачное некоммерческое партнерство поселений
  | 'suburbanNonProfitSettlementsPartnership'
  // Участок водного фонда
  | 'waterArea';
// Тип участка
export type TLandType = 'owned' | 'rent';
export type TOfferDetailLayout = 'cabinet' | 'mixed' | 'openSpace';
export type TOfferType = 'flat' | 'suburban' | 'commercial';
export type TOfferStatus = 'published';
export type TUserTrustLevel = 'involved';

export type THomeownerProof =
  /** Прошёл идентификацию по паспорту */
  | 'passport'
  /** Прошёл проверку в Росреестре */
  | 'rosreestr'
  /** Зарегистрирован в ФНС как самозанятый */
  | 'selfEmployed';

export interface ILivingAreaDetails {
  roomArea: string | null;
  totalArea: string;
  livingArea: string;
  kitchenArea: string;
  roomsCount: number | null;
}

export interface IFloorDetails {
  floorNumber: number | null;
  balconiesCount: number | null;
  loggiasCount: number | null;
}

export interface IPessimizationDetails {
  isRecidivist: boolean | null;
  isExcludedFromAction: boolean | null;
}

export interface IAllFromOffrep {
  count: number | null;
  url: string | null;
}

export interface IBuildingDeadline {
  isComplete: boolean;
  quarter: string;
  year: number;
}

export interface IBuilding {
  accessType: TAccessType | null;
  buildYear: number | null;
  cargoLiftsCount: number | null;
  deadline: IBuildingDeadline | null;
  floorsCount: number;
  materialType: TBuildingMaterial | null;
  passengerLiftsCount: number | null;
  type: TBuilding | null;
  classType?: TCommercialClass | null;
  parking?: IParkingSchema | null;
}

export interface IParkingSchema {
  placesCount?: number | null;
}

export interface IOfferBSCenter {
  buildingClassType: TCommercialClass;
  fromRepresentative: boolean;
  fullUrl: string;
  name: string;
  parentId: string;
  parentName: string;
  parentUrl: string;
  type: TOfferBSCenterType;
  useParentName: boolean;
  id: number | null;
}

export interface IGarage {
  // Тип гаража
  garageType: TGarageGarageType | null;
  // Тип бокса
  material: TGarageMaterial | null;
  // Статус
  status: TGarageStatus | null;
  // Тип
  type: TGarageType | null;
}

export enum ELocationTypeId {
  City = 1,
}

export interface IAddress {
  id: number;
  name: string;
  fullName: string;
  shortName: string;
  type: 'location' | 'street' | 'house' | 'metro';
  isFormingAddress: boolean;
  locationTypeId: ELocationTypeId | null;
  qs: string;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IDistrictInfo {
  id: number | null;
  locationId: number | null;
  name: string | null;
  parentId: number | null;
}

export interface IHighwayInfo {
  distance: string | number | null;
  id: number | null;
  isDefault: boolean | null;
  name: string | null;
  qs: string;
}

export interface IRailway {
  directionIds?: number[];
  distance?: string | null;
  geoType: string;
  qs: string;
  id: number;
  name: string;
  time: number;
  travelType: 'byFoot' | 'byCar';
}

export interface IUnderground {
  cianId: number | null;
  id: number;
  isDefault: boolean;
  lineColor: string;
  lineId: number;
  name: string;
  qs: string;
  time: number;
  transportType: 'walk' | 'transport';
  underConstruction?: boolean;
  releaseYear?: number;
}

export interface IDeveloper {
  id: number | null;
  name: string | null;
}

export interface IJkGaGeo {
  cityId: number | null;
  moId: number | null;
  oblId: number | null;
}

export interface IJKHouse {
  id: number | null;
  name: string | null;
}

export interface IJK {
  developer: IDeveloper | null;
  displayName: string | null;
  fullUrl: string | null;
  gaGeo: IJkGaGeo | null;
  house: IJKHouse | null;
  id: number | null;
  name: string | null;
  webSiteUrl: string | null;
}

export interface IGeo {
  address: IAddress[];
  buildingAddress: string | null;
  coordinates: ICoordinates;
  countryId: number;
  districts: IDistrictInfo[];
  highways: IHighwayInfo[];
  undergrounds?: IUnderground[];
  railways?: IRailway[];
  userInput: string;
  sortedGeoIds?: TGeoValue[];
  jk?: IJK;
}

export interface IKpData {
  id: number;
  name: string;
  displayBuilderPhone: boolean;
  url: string;
  promotions?: IVillagePromotions[];
  villageAdvantages: IVillageAdvantage[] | null;
}
export interface IVillagePromotions {
  /** Описание акции **/
  description?: string | null;
  /** Дата окончания акции **/
  endDate: string;
  /** ID акции **/
  id: number;
  /** Название акции **/
  name: string;
  /** Тип акции **/
  type: EType;
  /** ID КП **/
  villageId?: number;
}
export enum EType {
  /** Бонус **/
  Bonus = 'bonus',
  /** Материнский капитал **/
  MaternalCapital = 'maternalCapital',
  /** Военная ипотека **/
  MilitaryMortgage = 'militaryMortgage',
  /** Ипотека **/
  Mortgage = 'mortgage',
  /** Скидка **/
  Sale = 'sale',
}
export interface IVillageAdvantage {
  /** Тип преимущества */
  advantageType: EKPAdvantage;
  /** Детали преимущества */
  advantageTypeDetails: string[];
  /** Описание преимущества */
  text: string;
}
export enum EKPAdvantage {
  /** Рядом водоем */
  Water = 'water',
  /** Образование */
  Education = 'education',
  /** Лес */
  Forest = 'forest',
  /** Газ */
  Gas = 'gas',
  /** Детская площадка */
  Playground = 'playground',
  /** Канализация */
  Sewery = 'sewery',
  /** Водоснабжение */
  Plumbing = 'plumbing',
  /** Асфальт на дорогах */
  Entrance = 'entrance',
}

export interface ILand {
  area: string | number | null;
  areaUnitType: TLandAreaUnitType | null;
  possibleToChangeStatus: boolean | null;
  status: TLandStatus | null;
  type: TLandType | null;
}

export interface IHouseFinishDate {
  year: number;
  quarter: number;
}

export interface IOfferHouse {
  finishDate?: IHouseFinishDate | null;
  id?: number | null;
  isFinished?: boolean | null;
  name?: string | null;
  section?: string | null;
}

export interface INewbuilding {
  id: string | null;
  name: string | null;
  isFromBuilder: boolean | null;
  isFromSeller: boolean | null;
  isFromDeveloper: boolean | null;
  isFromLeadFactory?: boolean;
  showJkReliableFlag: boolean | null;
  house?: IOfferHouse | null;
}

export interface INotes {
  offer: {
    text: string;
    timestamp: string;
  } | null;
  realtor: {
    text: string;
    timestamp: string;
  } | null;
}

export interface IPhone {
  countryCode: string | null;
  number: string | null;
}

export interface IOfferPhoto {
  id: number;
  fullUrl: string;
  thumbnailUrl: string;
  thumbnail2Url: string;
  rotateDegree: number;
  isDefault: boolean;
  /** Планировка */
  isLayout: boolean;
  miniUrl: string;
}

export interface IPromoInfo {
  priceBeforeDiscount: string | null;
  certificate: boolean;
  end: string;
  name: string;
  certificateUrl: string | null;
  specProject: boolean;
}

export interface ISimilar {
  count: number | null;
  url: string | null;
}

export interface ISpecialty {
  // Английское название
  engName: string | null;
  // ID
  id: number | null;
  // Русское название
  rusName: string | null;
}

export interface ICommercialSpecialty {
  // Дополнительные виды
  additionalTypes: string[] | null;
  // Cпециализация
  specialties: ISpecialty[] | null;
  // Коды специализаций помещений
  types: string[] | null;
}

export interface IAgentAvailability {
  /** Доступен ли агент в данный промежуток времени в таймзоне агента */
  available: boolean | null;
  /** тут пользователь - автор объявления */
  userId: string | null;
  /** Заголовок с текстом для не рабочего времени в таймзоне +3 */
  title: string | null;
  /** Сообщение с текстом для не рабочего времени в таймзоне +3 */
  message: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableFrom: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableTo: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  vacationTo: string | null;
}

export interface IAgent {
  accountType: string | null;
  agencyName: string | null;
  agentAvailability: IAgentAvailability | null;
  agentAvatarUrl: string | null;
  cianProfileStatus: 'approved';
  cianUserId: number;
  experience: string | null;
  isAgent: boolean;
  isBuilder: boolean | null;
  isCianPartner: boolean;
  isCallbackUser: boolean | null;
  isChatsEnabled: boolean;
  isHidden: false;
  /** Прошёл идентификацию по паспорту */
  isPassportVerified: boolean | null;
  /** Зарегистрирован в ФНС как самозанятый */
  isSelfEmployed: boolean | null;
  personalRating: 'negative' | 'positive' | null;
  phoneNumbers: IPhone[] | null;
  profileUri: string | null;
  subAgentCompanyName: string | null;
  userTrustLevel: TUserTrustLevel;
  masterAgent?: IMasterAgent | null;
  /** Доступен онлайн просмотр */
  canShowOnline?: boolean | null;
  bank: string | null;
  agentModerationInfo?: AgentModerationInfoSchema | null;
  agentLists?: string[] | null;
  /** Форматированный тип пользователя для электронных торгов **/
  formattedPublisherType?: string;
}

export interface IMasterAgent {
  id: number | string | null;
  isModerationPassed?: boolean | null;
  absoluteMiniAvatarUrl?: string | null;
  miniAvatarUrl?: string | null;
  absoluteAvatarUrl?: string | null;
  avatarUrl?: string | null;
  name: string | null;
  accountType: string | null;
  profileUri: string | null;
}

export interface IOfferVideo {
  duration: number | null;
  type: string | null;
  uploadDate: string | null;
  url: string | null;
}

export interface IWorkTimeInfo {
  timezoneText: string;
  callFrom: string;
  callTo: string;
  isAvailableToCall: boolean;
}

export enum ERosreestrCheckStatus {
  InProgress = 'inProgress',
  Succeed = 'success',
  Failed = 'fail',
}

export interface IRosreestrCheck {
  status: ERosreestrCheckStatus;
}

export type TUserOfferId = number | undefined;

export interface IOfferServices {
  isTop3: boolean | null;
  isPremium: boolean | null;
  isColorized: boolean | null;
  isPaid: boolean | null;
  isAuction: boolean | null;
}

export interface IAuction {
  /** Возможность изменения ставки */
  isEditable: boolean;
  /** Объявление текущего пользователя или сабаккаунта */
  isOwn: boolean;
  /** Текущая ставка */
  currentBet: number;
  /** Id объявления пользователя на странице выдачи, ниже текущего объявления */
  userOfferId?: number | undefined;
  /** Сервисы объявления пользователя на странице выдачи, ниже текущего объявления */
  userOfferServices?: IOfferServices;
  /** Предыдущая ставка на объявление(возвращается в случае понижения ставки) */
  nextBet?: number | null;
  /** Дата применения новой ставки(возвращается в случае понижения ставки) */
  applyNewBetDate?: string | null;
  /** Максимально доступная ставка */
  maxAvailableBet: number;
  /** Шаг ставки */
  stepBet: number;
}

/**
 * тип расширения выдачи
 */
export enum EOfferExtensionType {
  /** выдача расширена удалением некоторых фильтров */
  Filters = 'filters',
  /** выдача расширена по гео */
  Geo = 'geo',
  /** в выдачу добавлены пригороды */
  Outskirts = 'outskirts',
  /** выдача расширена по цене */
  Price = 'price',
}

export interface ISearchOfferLabel {
  backgroundColor: string;
  textColor: string;
  text: string;
}

export interface IOfferExtensionLabel {
  labelType: EOfferExtensionType;
  value: string;
}

/** Типы коворкингов */
export enum ECoworkingOfferType {
  /** Гибкий офис */
  Office = 'office',
  /** Закрепленное рабочее место */
  FixedWorkplace = 'fixedWorkplace',
  /** Незакрепленное рабочее место */
  FlexibleWorkplace = 'flexibleWorkplace',
  /** Конференц-зал */
  ConferenceHall = 'conferenceHall',
  /** Переговорная комната */
  MeetingRoom = 'meetingRoom',
}

export enum EBrandingLevel {
  FirstLevel = 'firstLevel',
  SecondLevel = 'secondLevel',
  ThirdLevel = 'thirdLevel',
}

export interface ICoworkingWorkTime {
  /** Работает с */
  timeFrom: string;
  /** Работает до */
  timeTo: string;
}

export interface ICoworkingAmenity {
  key: string;
  title: string;
}

export interface ICoworking {
  /** Название */
  name?: string;
  /** Доступ - С понедельника по пятницу | Ежедневно */
  accessType?: 'weekdays' | 'daily';
  /** Круглосуточно */
  isRoundTheClock?: boolean;
  /** Рабочее время */
  workTime?: ICoworkingWorkTime;
  /** Удобства */
  includedAmenities?: ICoworkingAmenity[];
  /** Чисто удобств */
  moreAmenitiesCount?: number;
}

export interface IAreaPart {
  area?: number;
  price?: number;
  floorFrom?: number;
  floorTo?: number;
}

export interface IOffer extends ILivingAreaDetails, IFloorDetails, IPessimizationDetails {
  areaParts?: IAreaPart[];
  /** Данные о собственнике коммерческой недвижимости **/
  commercialOwnership?: ICommercialOwnershipSchema | null;
  chatId?: number;
  accessType: TAccessType | null;
  added: string;
  addedTimestamp: number;
  adfoxParams: { [key: string]: string | number | null };
  allFromOffrep: IAllFromOffrep | null;
  bargainTerms: IBargainTerms;
  bedroomsCount: number | null;
  booking: IBookingData | null;
  brandingLevel: EBrandingLevel | null;
  building: IBuilding | null;
  businessShoppingCenter: IOfferBSCenter | null;
  categoriesIds: number[] | null;
  category: TOfferCategory;
  cianId: number;
  cianUserId: number | null;
  coworkingOfferType?: ECoworkingOfferType | null;
  coworking?: ICoworking;
  dealType: TDealType;
  demolishedInMoscowProgramm: boolean;
  description: string | null;
  descriptionWordsHighlighted: [number, number][] | null;
  factoids?: IFactoid[] | null;
  flatType: string | null;
  fromDeveloper: boolean | null;
  fullUrl: string;
  externalOfferUrl: string;
  gaLabel: string | null;
  garage: IGarage;
  geo: IGeo | null;
  homeownerProofs: Array<THomeownerProof> | null;
  humanizedTimedelta: string;
  id: number;
  isAuction: boolean | null;
  auction: IAuction | null;
  isApartments: boolean | null;
  isByHomeowner: boolean | null;
  isColorized: boolean | null;
  isDuplicatedDescription: boolean | null;
  isFavorite: boolean | null;
  isImported: boolean;
  isInHiddenBase: boolean | null;
  isPaid: boolean | null;
  isPremium: boolean | null;
  isPro: boolean | null;
  isRentByParts: boolean | null;
  isResale: boolean | null;
  isStandard: boolean | null;
  isCianPartner: boolean;
  isTop3: boolean | null;
  isNeedHideExactAddress?: boolean;
  isUnique: boolean;
  jkUrl: string | null;
  kp: IKpData | null;
  land: ILand | null;
  layout: TOfferDetailLayout;
  /**
   * Баллы ликвидности, определяющие ликвидность объекта - привлекательность для поискуна.
   * Увидеть их может только авторизованный риелтор, на своем объявлении.
   * Баллы ликвидности чужих объявлений риелтору неизвестны.
   */
  liquidityPoints: number | null;
  monthlyIncome?: IMonthlyIncome;
  minArea: string | null;
  minPricePerMeterPerYearRur?: number;
  minPricePerMeterRur?: number;
  minPriceTotalPerMonth: number;
  minPriceTotalPerMonthRur: number | null;
  minPriceTotalRur: number | null;
  minVatPriceTotalPerMonthRur: number | null;
  minVatPriceTotalRur: number | null;
  modelVersion: number | null;
  newbuilding: INewbuilding | null;
  readyBusinessType?: EReadyBusiness;
  notes: INotes;
  offer: string;
  offerFeatureLabels?: string[] | null;
  offerType: TOfferType;
  permittedUseType: string | null;
  phones: IPhone[] | null;
  photos: IOfferPhoto[];
  pricePerUnitAreaPerYearRur: number;
  pricePerUnitAreaRur: number;
  priceTotalPerMonthRur: number;
  profitbaseBooking?: IProfitbaseBooking;
  promoInfo: IPromoInfo | null;
  publishedUserId: number | null;
  rentByPartsDescription: string | null;
  roomsForSaleCount: number | null;
  rosreestrCheck: IRosreestrCheck | null;
  shareAmount: string;
  similar: ISimilar;
  specialty: ICommercialSpecialty | null;
  status: TOfferStatus;
  suggestionDistances?: number;
  title: string | null;
  /** Принадлежность объявления к блоку Топ-3 (верхний или нижний) */
  top3Place: string | null;
  user: IAgent | null;
  userId: number | null;
  vatPriceTotalPerMonthRur: number | null;
  vatPriceTotalRur: number | null;
  videos: IOfferVideo[] | null;
  workTimeInfo: IWorkTimeInfo | null;
  /**
   * Флаг отвечающий за показ лейба "новое"
   * Актуально только для выдачи по сохраненным поискам
   */
  isNew?: boolean;
  savedSearchLabels?: Array<ISearchOfferLabel>;
  /** Подменен ли номер телефона */
  isDealRequestSubstitutionPhone: boolean;
  labels?: string[];
  /** принадлежность оффера к определенному типу расширения выдачи с лейблом */
  extensionLabels?: IOfferExtensionLabel[];
  identicalOffers?: IIdenticalOffers | null;
  photoLabels?: IPhotoLabel[] | null;
  /** Количество рабочих мест */
  workplaceCount?: number | null;
  offerInDeal?: boolean;
  showWarningMessage: boolean;
  /** Данные модерации для отображения **/
  moderationInfo?: IModerationInfoSchema | null;
  /** наличие 3d-тура */
  isNewbuildingTourAvailable: boolean;
  /** У объявления включен коллтрекинг */
  isCalltrackingEnabled?: boolean | null;
  photoFeatureIcons: IPhotoFeatureIcons[];
  comparisonStatus?: IOfferComparisonStatus | null;
  showCommercialAnalytics?: boolean | null;
  searchDuplicatesRotationPromo?: string | null;
  /**
   * @todo Удалить строку с экспериментом
   * @description Данный функционал появился в задаче CD-128338, будет удалён в задаче CD-128351
   * Если эксперимент будет удачным, то оставить строку, но удалить комментарий,
   * если эксперимент будет не удачным, то удалить полностью строку
   */
  hasFairPrice?: boolean;
  isUniqueForCian?: boolean;
  bestPlaceAnalyticsAvailable?: boolean;
  tradingLink?: string | null;
}

export interface ICommercialOwnershipSchema {
  /** Объявление от подтверждённого собственника **/
  isFromOwner: boolean;
}

export enum EReadyBusiness {
  RentalBusiness = 'rentalBusiness',
  Other = 'other',
  ReadyBusiness = 'readyBusiness',
}

export type TCurrency = 'eur' | 'rur' | 'usd';

export interface IMonthlyIncome {
  currency: TCurrency;
  income: number;
}
