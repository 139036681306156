import { pathOr } from 'ramda';

import { IOffer } from '../repositories/search-offers.legacy/v2/types';

export function getOfferExtra(offer: IOffer) {
  const { isCianPartner, fullUrl } = offer;

  if (isCianPartner) {
    return {
      sutochno_feed_test: true,
      sutochno_url: fullUrl,
    };
  }

  if (offer.dealType !== 'sale') {
    return undefined;
  }

  const isFromDeveloper = pathOr(false, ['newbuilding', 'isFromDeveloper'], offer);
  const isFromLeadFactory = pathOr(false, ['newbuilding', 'isFromLeadFactory'], offer);

  return {
    cplDynamicCalltracking: 0,
    cplCalltracking: isFromDeveloper && !isFromLeadFactory ? 1 : 0,
  };
}
