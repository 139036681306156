import { memo } from 'react';

import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { AdfoxContextData, AdfoxContextProvider, PParams } from '../../context/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'offersSerp',
  name__DEPRECATED: 'Listing_native_TGB',
  extra__DEPRECATED: {
    offerType: 'TGB_banner',
  },
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '275px' };
const P_PARAMS: PParams = { p1: 'ddcfw', p2: 'fkhj' };

type TGBBannerProps = {
  position: number;
};

export const TGBBanner = memo<TGBBannerProps>(function TGBBanner(props) {
  const { position } = props;

  return (
    <AdfoxContextProvider pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE}>
      <AdfoxBannerContainer position={position} />
    </AdfoxContextProvider>
  );
});
