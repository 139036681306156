import { IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useBooleanState } from '@cian/react-utils';

import { JSX, memo, useCallback, useMemo } from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

type AdFoxBannerContainerProps = {
  galleryPosition?: number;
  position?: number;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
};

export const AdfoxBannerContainer = memo<AdFoxBannerContainerProps>(function AdFoxBanner(props) {
  const { onStub, onError, position, galleryPosition } = props;

  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const { state: isHidden, setTrue: setHidden } = useBooleanState(false);

  const handleStub = useCallback(() => {
    setHidden();

    onStub?.();
  }, []);

  const handleError = useCallback((error: IAdFoxBannerOnErrorError) => {
    setHidden();

    onError?.(error);
  }, []);

  return useMemo((): JSX.Element | null => {
    if (isHidden) {
      return null;
    }

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdfoxBanner, {
      ...postViewAnalyticsData,
      position,
      galleryPosition,
    });

    return <AdfoxWithPostView {...size} params={params} onError={handleError} onStub={handleStub} />;
  }, [isHidden]);
});
